*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  background-clip: padding-box;
  border-radius: 3px;
  padding-right: 2px;
}
* .ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  white-space: nowrap;
}
